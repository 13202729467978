import { Menu } from '../types/menu';

export const YOUR_SWIMS_ROUTE = '/swimbetter/swimmers/your-swims';
export const ADMINS_COACHES_DASHBOARD_ROUTE =
  '/swimbetter/admins-and-coaches/team-dashboard';

const YOUR_SWIMS_MENU: Menu = {
  // This is the old tempo

  STROKE_RATE_AND_FORCE: {
    name: 'stroke-rate-and-force',
    endpoint: `${YOUR_SWIMS_ROUTE}/stroke-rate-and-force`,
    url: 'stroke-rate-and-force',
    videoSync: false,
    licenceAccess: ['Silver', 'Gold'],
  },
  FORCEFIELD: {
    name: 'forcefield',
    endpoint: `${YOUR_SWIMS_ROUTE}/forcefield`,
    url: 'forcefield',
    videoSync: false,
    licenceAccess: ['Silver', 'Gold'],
  },
  STROKE_PATH_AND_HAND_VELOCITY: {
    name: 'stroke-path-and-hand-velocity',
    endpoint: `${YOUR_SWIMS_ROUTE}/stroke-path-and-hand-velocity`,
    url: 'stroke-path-and-hand-velocity',
    videoSync: false,
    licenceAccess: ['Gold'],
  },
  // HAND_PATH: {
  //   name: 'hand-path',
  //   endpoint: `${YOUR_SWIMS_ROUTE}/stroke-path-and-hand-velocity`,
  //   url: 'stroke-path-and-hand-velocity',
  //   videoSync: false,
  //   licenceAccess: ['Silver'],
  // },
  CONSISTENCY: {
    name: 'consistency',
    endpoint: `${YOUR_SWIMS_ROUTE}/consistency`,
    url: 'consistency',
    videoSync: false,
    licenceAccess: ['Gold'],
  },
  STROKE_PHASES: {
    name: 'stroke-phases',
    endpoint: `${YOUR_SWIMS_ROUTE}/stroke-phases`,
    url: 'stroke-phases',
    videoSync: false,
    licenceAccess: ['Silver', 'Gold'],
  },
  FORCE_VS_TIME: {
    name: 'force-vs-time',
    endpoint: `${YOUR_SWIMS_ROUTE}/force-vs-time`,
    url: 'force-vs-time',
    videoSync: true,
    licenceAccess: ['Gold'],
  },
  STROKE_PATH_AND_FORCE: {
    name: 'stroke-path-and-force',
    endpoint: `${YOUR_SWIMS_ROUTE}/stroke-path-and-force`,
    url: 'stroke-path-and-force',
    videoSync: false,
    licenceAccess: ['Silver', 'Gold'],
  },
};

export { YOUR_SWIMS_MENU };
